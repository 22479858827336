/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ohioans are just like any other Americans. They rely on their ears and their auditory systems to get them through the day, whether it’s in demanding workplaces, sports venues, bars, restaurants or just at home watching some TV. Despite this, hundreds of thousands of people in the Buckeye State haven’t sought out appropriate assistance to solve their hearing issues."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It’s a big deal. Ohio is a state of around 11 million people. About 10 percent of Americans experience medium to severe hearing loss, adding up to at least a million Ohioans. As about a third of those who need hearing aids actually use them, a huge chunk of the state’s population is tolerating poor (and probably deteriorating) hearing. And that can hurt you in your career, social life and family life. But it’s often totally unnecessary."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "dangerous-noise-levels-in-ohio",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#dangerous-noise-levels-in-ohio",
    "aria-label": "dangerous noise levels in ohio permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Dangerous noise levels in Ohio"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Even people in their 40s can start to experience noticeable hearing loss, especially in a state like Ohio, where loud noises are part of everyday life. Music is one common source of exposure, particularly in a state that hosts the Rock and Roll Hall of Fame and goes nuts for live music. And farming is another state specialty that results in serious noise exposure."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With over 70,000 farms and plenty more professionals who use heavy equipment on a daily basis, rural life can take its toll on your ears. And cities like Cleveland, Akron, Youngstown are famous for their heavy industries, where noise exposure is often taken for granted. Then there’s sport. Whether you’re a Bengals or a Browns fan, or you regularly watch the Indians at Progressive Field, live sport is a massive source of noise. In fact, NFL games average around 80 decibels (and can go as high as 140 when the crowd really starts to roar). 85 decibels is generally seen as the level where employers should start providing protective equipment to staff, so the thousands of sports fans who support their team week-in, week-out are putting their hearing under strain."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Another source of dangerous noise is hunting and guns. 20 percent of Ohioans own guns and deer-hunting is a popular pastime across the state. But every time you level your rifle, you risk damaging your ears. That’s not a reason to stop hunting. But it’s just another one of the many sources of noise in Ohio which add up. Eventually, they turn into serious hearing loss and seeking help becomes essential."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "ohio-in-numbers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ohio-in-numbers",
    "aria-label": "ohio in numbers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ohio in Numbers"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To put everything in perspective, let’s recap the statistics on hearing loss in Ohio. With a population of 11 million, around 1 million adults in Ohio experience moderate to severe hearing loss and far more have some form of hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "About 1 in 6 baby boomers in Ohio experience hearing loss, but the number rises rapidly with age, as around half of those aged over 75 report noticeable hearing loss. Nationally, about 15 percent of hearing loss is caused by occupational factors. Again, that adds up to hundreds of thousands of Ohio natives."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "All over the USA, some 30 million people could benefit from hearing aids. As we noted above, only a third or so of these people actually use hearing aids, and the numbers are even lower for those under 70 years of age."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "combat-hearing-loss-in-ohio",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#combat-hearing-loss-in-ohio",
    "aria-label": "combat hearing loss in ohio permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Combat hearing loss in Ohio"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Many people who suffer from hearing loss come to a sharp realization that they have a problem. After years of noticing slight differences, suddenly their conversations become difficult, or the music seems more distant. If that sounds like you, there’s no need to panic."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com has a network of Partner Providers across Ohio and can put you in touch with the expertise required to resolve your hearing issues. And we also offer the most advanced hearing aids from global market leaders, allowing Ohioans to enjoy the highest possible quality of life."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "get-in-touch-and-take-action",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-in-touch-and-take-action",
    "aria-label": "get in touch and take action permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get in touch and take action"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To access our services, sign up online for our risk-free trial. After that, we can suggest a hearing aid and connect you with a specialist in your neighborhood. Our network includes many Partner Providers in Ohio, so everyone shouldn’t be far from a location."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "From Chillicothe to Cleveland and from Columbus to Akron, our experts are close by. Even if you’re tucked away in rural areas, we should be able to help. So, don’t hesitate to make contact. The more you delay, the worse your hearing will become, and prompt action now can avoid rapid deterioration further down the line. Just fill out our questionnaire and get started on the road to restored hearing."), "\n", React.createElement(LandingPageCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
